import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  transform(link: string): string {
    let replacedMessage = null;

    // Regex to match URLs that are not within <a> tags
    const urlPattern =
      /\b(http|https|ftp):\/\/[^\s<>"']+(?![^<]*(<\/a>))/gim;

    // Regex to match URLs inside <a> tags
    const anchorPattern = /<a\s+href="([^"]*)">([^<]*)<\/a>/g;

    // Find URLs inside <a> tags
    const anchorMatches = [];
    let match;

    while ((match = anchorPattern.exec(link)) !== null) {
      anchorMatches.push({ href: match[1], text: match[2] });
    }

    // Replace URLs outside <a> tags
    replacedMessage = link.replace(urlPattern, (url) => {
      // Skip replacement if the URL is already in <a> tag
      if (anchorMatches.some((anchor) => anchor.href === url)) {
        return url;
      }
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const basePattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedMessage = replacedMessage.replace(
      basePattern,
      '$1<a href="http://$2" target="_blank">$2</a>',
    );
    // Chang email addresses to mailto:: links
    const emailPattern =
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedMessage = replacedMessage.replace(
      emailPattern,
      '<a href="mailto:$1">$1</a>',
    );
    return replacedMessage;
  }
}
